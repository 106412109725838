/*  imported from index.less */

@import '~@meepshop/utils/styles/variables.less';
#meepshop {
.meepshop-social-media__index__root {
  display: flex;
  width: 100%;

  &.meepshop-social-media__index__FLEX_START {
    justify-content: flex-start;
  }

  &.meepshop-social-media__index__CENTER {
    justify-content: center;
  }

  &.meepshop-social-media__index__FLEX_END {
    justify-content: flex-end;
  }

  > a {
    padding: 8px;
    height: 40px;

    @media (max-width: @screen-sm-max) {
      padding: 8px;
      height: 48px;
    }

    > svg {
      width: 24px;
      height: 24px;

      @media (max-width: @screen-sm-max) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.meepshop-social-media__index__facebook {
  fill: #369;

  &.meepshop-social-media__index__CIRCLE circle {
    fill: transparent;
  }
}

.meepshop-social-media__index__line {
  fill: #0c0;

  &.meepshop-social-media__index__CIRCLE {
    g:first-child > path {
      fill: transparent !important;
    }

    g:last-child > path {
      stroke: transparent !important;
    }
  }

  path + path:last-child {
    fill: #fff;
  }
}

.meepshop-social-media__index__twitter {
  &.meepshop-social-media__index__ORIGIN path {
    fill: white;
  }
  &.meepshop-social-media__index__ORIGIN react {
    fill: black;
  }

  &.meepshop-social-media__index__CIRCLE circle {
    fill: transparent;
  }
  &.meepshop-social-media__index__CIRCLE path {
    stroke: transparent !important;
  }
}

.meepshop-social-media__index__wechat {
  fill: #2dc100;

  &:not(.meepshop-social-media__index__NONE):not(.meepshop-social-media__index__CIRCLE) {
    path {
      fill: #fff;
    }
  }

  &.meepshop-social-media__index__CIRCLE {
    circle {
      fill: transparent;
    }

    path {
      stroke: transparent !important;
    }
  }

  @media (max-width: @screen-sm-max) {
    width: 32px;
    height: 32px;
  }
}

.a2a_button_wechat {
  width: 24px;
  height: 24px;
}
/* addToAny 會利用 script 塞入以下這個 className 的 span，並不是我們想要的圖示，故直接隱藏*/
.a2a_svg.a2a_s__default.a2a_s_wechat {
  display: none;
}

@media (max-width: @screen-sm-max) {
  .a2a_button_wechat {
    width: 32px;
    height: 32px;
  }
}
}
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";